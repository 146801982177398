.card {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.icon {
  width: 64px;
  height: 64px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  margin-left: 24px;
}

.chart {
  position: absolute;
  width: calc(100% + 8px);
  height: 100%;
  padding-top: 16px;
  bottom: -10px;
  left: -8px;
  z-index: 0;
}
