.root {
  padding: 24px;
}

.breadcrumb {
  height: 42px;
  display: flex;
  align-items: center;
  margin-top: 8px;
}
