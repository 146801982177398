.sad-head-icon {
  display: flex;
  border-radius: 100%;
  box-shadow: 0 0 0 3px rgba(251, 102, 102, 0);
  -webkit-box-shadow: 0 0 0 3px rgba(251, 102, 102, 0);
  z-index: 1;
  &:active {
    box-shadow: 0 0 0 5px rgba(251, 102, 102, 0.5);
    -webkit-box-shadow: 0 0 0 5px rgba(251, 102, 102, 0.5);
  }
  circle {
    fill: #FB6666;
  }
  &.mute {
    circle {
      fill: #EBEBEB;
    }
  }
  &.active {
    box-shadow: 0 0 0 3px rgba(251, 102, 102, 0.5);
    -webkit-box-shadow: 0 0 0 3px rgba(251, 102, 102, 0.5);
  }
}

.medium-head-icon {
  display: flex;
  border-radius: 100%;
  box-shadow: none;
  z-index: 1;
  box-shadow: 0 0 0 3px rgba(255, 212, 103, 0);
  -webkit-box-shadow: 0 0 0 3px rgba(255, 212, 103, 0);
  &:active {
    box-shadow: 0 0 0 5px rgba(255, 212, 103, 0.5);
    -webkit-box-shadow: 0 0 0 5px rgba(255, 212, 103, 0.5);
  }
  circle {
    fill: #FFD467;
  }
  &.mute {
    circle {
      fill: #EBEBEB;
    }
  }
  &.active {
    box-shadow: 0 0 0 3px rgba(255, 212, 103, 0.5);
    -webkit-box-shadow: 0 0 0 3px rgba(255, 212, 103, 0.5);
  }
}

.happy-head-icon {
  display: flex;
  border-radius: 100%;
  box-shadow: none;
  z-index: 1;
  box-shadow: 0 0 0 3px rgba(110, 204, 108, 0);
  -webkit-box-shadow: 0 0 0 3px rgba(110, 204, 108, 0);
  &:active {
    box-shadow: 0 0 0 5px rgba(110, 204, 108, 0.5);
    -webkit-box-shadow: 0 0 0 5px rgba(110, 204, 108, 0.5);
  }
  circle {
    fill: #6ECC6C;
    transition: 100ms ease-in-out background-color;
  }
  &.mute {
    circle {
      fill: #ebebeb;
    }
  }
  &.active {
    box-shadow: 0 0 0 3px rgba(110, 204, 108, 0.5);
    -webkit-box-shadow: 0 0 0 3px rgba(110, 204, 108, 0.5);
  }
}

.happy-head-icon, .medium-head-icon, .sad-head-icon {
  &.mute {
    g {
      opacity: 0.7;
    }
    &:active {
      box-shadow: 0 0 0 5px rgba(235, 235, 235, 0.5);
      -webkit-box-shadow: 0 0 0 5px rgba(235, 235, 235, 0.5);
    }
  }
}

@media (hover) {
  .happy-head-icon, .medium-head-icon, .sad-head-icon {
    &.mute {
      &:hover {
        box-shadow: 0 0 0 3px rgba(235, 235, 235, 0.5);
        -webkit-box-shadow: 0 0 0 3px rgba(235, 235, 235, 0.5);
      }
    }
  }
  .happy-head-icon {
    &:hover {
      box-shadow: 0 0 0 3px rgba(110, 204, 108, 0.5);
      -webkit-box-shadow: 0 0 0 3px rgba(110, 204, 108, 0.5);
    }
  }
  .medium-head-icon {
    &:hover {
      box-shadow: 0 0 0 3px rgba(255, 212, 103, 0.5);
      -webkit-box-shadow: 0 0 0 3px rgba(255, 212, 103, 0.5);
    }
  }
  .sad-head-icon {
    &:hover {
      box-shadow: 0 0 0 3px rgba(251, 102, 102, 0.5);
      -webkit-box-shadow: 0 0 0 3px rgba(251, 102, 102, 0.5);
    }
  }
}
