.chatLog {
  display: flex;
  flex-grow: 100;
  overflow: scroll;
  white-space: pre-wrap;
  height: fit-content;
  margin-bottom: 5em;
  overflow-x: hidden;
  overflow-y: hidden;
  z-index: 0;
}

.chatLog ul {
  padding: 0;
  width: 100%;
}

.chatLog ul li {
  transition: 1s;
  margin: 5px 15px 5px 15px;
  width: calc(100% - 25px);
  float: left;
}


.chatLog .sent {
  float: right;
  width: 100%;
}

.chatLog .sent img {
  float: right;
  width: auto;
  margin: 3px 0 0 8px;
  filter: brightness(1);
}

.chatLog .replies img {
  filter: brightness(.7);
}

.chatLog .bubble {
  display: inline-block;
  padding: 10px 15px;
  border-radius: 8px;
  line-height: 130%;
  margin-bottom: 5px;
  max-width: 43%;
  box-shadow: 5px 5px 15px darkgray;
}

.chatLog .ui.loader:after {
  border-color: white transparent transparent;
}

.userBlob {
  border-radius: 10px;
}

.userBlob .userBlobPaper {
  background-color: var(--main-bg-bot_user_blob_color);
  padding: 10px;
  margin-top: 8px;
  margin-left: 12vw;
  display: flex;
  color: white;
  font-size: 13px;
  float: right;
  border-radius: 8px;
}

.userTimestamp {
  color: #555555;
  font-size: 10px;
  margin-right: 4px;
  float: right;
  margin-top: 8px;
}

.botAvatar {
  padding-left: 10px;
  float: right;
  object-fit: contain;
}

#AvatarStroke{
  stroke: var(--main-bg-bot_avatar_icon_color);
}

#AvatarFill{
  fill: var(--main-bg-bot_avatar_icon_color);
}

#IconDownFill{
  fill: var(--main-bg-bot_header_expand_color);
}

#DownloadIconFill{
  fill: var(--main-bg-bot_header_download_color);
}

#LinkIconFill{
  fill: var(--main-bg-bot_header_link_color);
}

.botBlob {
  border-radius: 20px;
  margin-top: 4px;
}

.botBlob a {
  color: var(--main-bg-bot_blob_link_text_color)
}

.botAvatar {
  width: 50px;
  height: 50px;
  float: left;
  position: static;
  vertical-align: middle;
}

.botBlob .botBlobPaper {
  background-color: var(--main-bg-bot_blob_color);
  float: left;
  padding: 10px;
  color: #202020;
  font-size: 13px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  max-width: 600px;
}

.BotBlob .botBlobPaper ul {
  margin-top: 0;
}

.botBlob .botBlobOption {
  float: left;
  margin-right: 8px;
  color: var(--main-bg-bot_blob_option_text_color);
  border-radius: 8px;
  border: 1px solid var(--main-bg-bot_blob_option_border_color);
  font-size: 12px;
  line-height: 14px;
  margin-top: 8px;
  text-transform: none;
}

.botBlobOption text {
  margin: 0;
}

.botBlobOption {
  background-color: var(--main-bg-bot_blob_option_background_color);
  color: var(--main-bg-bot_blob_option_click_text_color);
}

.botBlob .botBlobLink {
  float: left;
  color: var(--main-bg-bot_blob_link_text_color);
  border-radius: 8px;
  background-color: var(--main-bg-bot_blob_color);
  border: 1px solid var(--main-bg-bot_blob_option_border_color);
  font-size: 12px;
  padding: 6px;
  text-decoration: underline;
}

.iconAlign {
  align-self: center;
}

.botBlob .botBlobExpandPaper {
  background-color: var(--main-bg-bot_blob_color);
  color: #202020;
  font-size: 12px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  border-radius: 8px;
  overflow: hidden;
  padding-top: 4px;
  padding-bottom: 4px;
}

.botBlobExpand {
  background-color: var(--main-bg-bot_blob_color) !important;
  box-shadow: none !important;
  color: var(--main-bg-bot_user_blob_color) !important;
}

#expandIcon {
  color: var(--main-bg-bot_user_blob_color);
}


.MuiGrid-root.MuiGrid-item .botBlopExpandPaper div {
  margin-left: 24px;
}

#beforeexpand {
  padding: 10px 23px 10px 17px;
}

#afterexpand {
  padding: 10px 23px 10px 17px;
}

/* 
Each expand panel/row
*/
#panel1a-header.MuiButtonBase-root.MuiExpansionPanelSummary-root {
  display: flex;
  padding: 2px 24px 2px 17px;
  min-height: 0px;
  transition: min-height 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  justify-content: left;
}

/*
Content inside of panel/row
*/
#panel1a-header.MuiButtonBase-root.MuiExpansionPanelSummary-root .MuiExpansionPanelSummary-content {
  max-width: 279px;
  margin: 0px 0;
  display: flex;
  flex-grow: 1;
  transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.MuiButtonBase-root.MuiIconButton-root.MuiExpansionPanelSummary-expandIcon.MuiIconButton-root {
  margin-left: 40px;
  padding: 2px 0px;
  flex: 0 0 auto;
  color: rgba(0, 0, 0, 0.54);
  overflow: visible;
  font-size: 1.5rem;
  text-align: center;
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 50%;
}

.botBlobExpandDetail {
  color: black;
  display: flex;
  padding: 8px 24px 24px 17px;
  max-width: 319px;
}

.botBlobImg {
  width: 100%;
  object-fit: contain;
  max-width: 360px;
}

/* 
If there is no title / descr with image, below design is used.
*/
.responseImg {
  margin-top: 8px;
  border-radius: 8px;
  margin-right: 8px;
}

/* 
If title / descr is given with image, below design is used.
*/
.responseImgWithText {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-right: 8px;
}

/*
Text below image 
*/
.responseImgText {
  max-width: 360px;
  width: 100%;
  background-color: var(--main-bg-bot_blob_color);
  padding-bottom: 10px;
  color: #202020;
  font-size: 12px;
  margin-top: -6px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.player {
  position: inherit;
}

.timestamp {
  color: #555555;
  font-size: 10px;
  margin-left: 4px;
  margin-top: 8px;
}

.MuiRating-root {
  color: var(--main-bg-bot_submit_button_color) !important;
}