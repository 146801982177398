.DraftEditor-editorContainer a {
    white-space: pre-wrap;
    display: inline;
}

.DraftEditor-editorContainer span {
    white-space: pre-wrap;
}

.public-DraftStyleDefault-block {
    padding: 0 !important;
    background-color: white !important;
}

div[class^="CodeBlock-root-"] {
    padding: 0 !important;
    background-color: white !important;
}

div[class^="CodeBlock-root-"] {
    padding: 0 !important;
    background-color: white !important;
}

.public-DraftEditor-content div {
    padding: 0 !important;
    background-color: white !important;
}

pre {
    font-family: 'IBM Plex Sans', sans-serif;
}