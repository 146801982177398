* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  background-color: #f4f6f8;
  height: 100%;
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}


:root {
  --main-bg-bot_header_color: #02718F;

  --main-bg-bot_blob_color: #F1F0F0;
  --main-bg-bot_user_blob_color: #02718F;

  --main-bg-bot_blob_link_text_color: #02718F;

  --main-bg-bot_blob_option_text_color: #202020;
  --main-bg-bot_blob_option_click_text_color: #F1F0F0;
  --main-bg-bot_blob_option_background_color: #02718F;
  --main-bg-bot_blob_option_border_color: #02718F;

  --main-bg-bot_input_box_color: #fdfcf8;
  --main-bg-bot_submit_button_color: #02718F;
  --main-bg-bot_submit_button_text: #F1F0F0;

  /* Icons */
  --main-bg-bot_header_link_color: #02718F;
  --main-bg-bot_header_download_color: #02718F;
  --main-bg-bot_header_expand_color: #02718F;
  --main-bg-bot_header_overflow_color: #F1F0F0;

  --main-bg-bot_avatar_color: #02718F;
  --main-bg-bot_avatar_icon_color: #02718F;

  --main-bg-bot_primary_color: #02718F;
  --main-bg-bot_secondary_color: #02718F;

}