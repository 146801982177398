#iframe {
  width: 100%;
  height: 100%;
  z-index: 2147483646;
  /*overflow: hidden;*/
  -webkit-overflow-scrolling: touch !important;
}

#innerFrame {
  top: 0;
  bottom: 0;
  position: absolute;
}

#chatbot-container {
  outline: none;
  -ms-overflow-style: none;
  position: fixed;
  bottom: 0;
  right: 3em;
  z-index: 999999999999;
  background: transparent;
}

@media screen and (min-width: 601px) {
  #iframe {
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
  }
}

@media screen and (max-width: 600px) {
  #chatbot-container.square {
    min-width: 250px;
    height: 100% !important;
    width: 100% !important;
    top: 0em;
    bottom: 0em;
    right: 0em;
    left: 0em;
  }

  #iframe {
    border-radius: 0px;
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
  }
}

@media screen and (max-height: 585px) {
  #chatbot-container.square {
    height: 100% !important;
    top: 0em;
  }
}

#chatbot-container.round {
  -ms-overflow-style: none;
  cursor: pointer;
  bottom: 3em;
  height: 120px;
  width: 100px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  background-color: transparent;
  z-index: 999999999;
}

#chatbot-container.round:focus {
  opacity: 0.6;
}

#chatbot-container.round:before {
  -ms-overflow-style: none;
  content: "";
  background-repeat: no-repeat;
  background-size: 85%;
  height: 120px;
  width: 100px;
  position: absolute;
  left: 14px;
  top: 18px;
}

#chatbot-container.square {
  -ms-overflow-style: none;
  height: 585px;
  width: 400px;
  overflow: hidden; /* Safari iframe fix */
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.5);
}

.hidden {
  display: none;
}

/* Close button */
#x {
  outline: none;
  cursor: pointer;
  color: white;
  position: absolute;
  top: 12px;
  right: 4px;
  margin-top: 0;
  margin-left: 0;
  width: 36px;
  height: 36px;
  background: none;
  z-index: 999999999999;
}

#x:focus {
  color: rgba(255, 255, 255, 0.5);
}

#x:before {
  content: "";
  position: absolute;
  top: 16px;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#x:after {
  content: "";
  position: absolute;
  top: 16px;
  width: 21px;
  height: 1px;
  background-color: currentColor;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

#x,
#chatbot-container {
  transition: all 0.4s ease;
}

#chatbot-container.square .chat .content .choice.topButton {
  margin: 14px 40px 0 0;
}

/* Shadow */
#chatbot-container-shadow {
  -ms-overflow-style: none;
  position: fixed;
  bottom: 0;
  right: 3em;
  background: white;
  z-index: 99999999;
}

#chatbot-container-shadow.round {
  -ms-overflow-style: none;
  bottom: 3em;
  height: 120px;
  width: 100px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  background-color: transparent;
}

#chatbot-container-shadow.round:before {
  -ms-overflow-style: none;
  content: "";
  background-repeat: no-repeat;
  background-size: 85%;
  background-image: url("/images/avatars/avatar_shadow.svg");
  height: 120px;
  width: 100px;
  position: absolute;
  left: 14px;
  top: 18px;
}

/* Animation */
.jump {
  transform-origin: 50% 50%;
  animation: jump 0.5s linear alternate;
  -moz-animation: jump 0.5s linear alternate;
  -o-animation: jump 0.5s linear alternate;
  -webkit-animation: jump 0.5s linear alternate;
  animation-delay: 2s;
  animation-iteration-count: 10;
}
@-moz-keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, -30%, 0) scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: translate3d(0, 5%, 0) scale3d(1.05, 0.95, 1);
  }
}

@-o-keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, -30%, 0) scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: translate3d(0, 5%, 0) scale3d(1.05, 0.95, 1);
  }
}

@-ms-keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, -30%, 0) scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: translate3d(0, 5%, 0) scale3d(1.05, 0.95, 1);
  }
}

@-webkit-keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, -30%, 0) scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: translate3d(0, 5%, 0) scale3d(1.05, 0.95, 1);
  }
}

@keyframes jump {
  0% {
    transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
  }
  40% {
    transform: translate3d(0, -30%, 0) scale3d(0.95, 1.05, 1);
  }
  100% {
    transform: translate3d(0, 5%, 0) scale3d(1.05, 0.95, 1);
  }
}

/* Text bubble */
.text-bubble {
  z-index: 999999999;
  font-size: 14px !important;
  line-height: 1.4 !important;
  -ms-overflow-style: none;
  position: fixed;
  bottom: 8em;
  right: 10.5em;
  background: white;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  max-width: 50%;
  width: auto;
  height: auto;
  padding: 12px;
  color: #202020;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.1), 0 6px 20px 0 rgba(0, 0, 0, 0.1) !important;
}

.text-bubble:after {
  content: "";
  position: absolute;
  right: 0;
  top: 70%;
  width: 0;
  height: 0;
  border: 1em solid transparent;
  border-left-color: white;
  border-right: 0;
  border-bottom: 0;
  margin-top: -0.5em;
  margin-right: -0.6em;
}

.text-bubble-in {
  animation-delay: 8s;
  animation-name: text-bubble-in;
  animation-duration: 0.5s;
  transform: scale(0);
  animation-fill-mode: forwards;
  transform-origin: bottom right;
}

.text-bubble-in-fast {
  animation-delay: 2s;
  animation-name: text-bubble-in;
  animation-duration: 0.5s;
  transform: scale(0);
  animation-fill-mode: forwards;
  transform-origin: bottom right;
}

@-moz-keyframes text-bubble-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-o-keyframes text-bubble-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-ms-keyframes text-bubble-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@-webkit-keyframes text-bubble-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes text-bubble-in {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.text-bubble-out {
  animation-name: text-bubble-out;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  transform-origin: bottom right;
}
@-moz-keyframes text-bubble-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-o-keyframes text-bubble-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-ms-keyframes text-bubble-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@-webkit-keyframes text-bubble-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes text-bubble-out {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

@media screen and (max-width: 600px) {
  #chatbot-container.round {
    top: unset;
    left: unset;
    bottom: 1em;
    right: 1em;
    height: 90px;
    width: 70px;
  }
  #chatbot-container.round::before {
    height: 90px;
    width: 70px;
    background-position: top center;
    top: 0;
    left: 0;
  }

  #chatbot-container-shadow.round {
    top: unset;
    left: unset;
    bottom: 1em;
    right: 1em;
    height: 90px;
    width: 70px;
  }
  #chatbot-container-shadow.round::before {
    height: 90px;
    width: 70px;
    background-position: bottom center;
    top: 0;
    left: 0;
  }
  .text-bubble {
    bottom: 80px;
    right: 100px;
  }
}
